@font-face {
    font-family: "font";
    src: url("../fonts/Hind-Medium.ttf");
}

@font-face {
    font-family: h1;
    src: url("../fonts/PragatiNarrow-Bold.ttf");
}

@font-face {
    font-family: sub-heading;
    src: url("../fonts/Basic-Regular.ttf");
}

h1 {
    font-family: h1;
    font-size: 7rem;
}

h2,
h3,
h4,
h5,
h6 {
    font-family: sub-heading;
    font-size: 3rem;
}

p,
a {
    font-family: font;
    font-size: 1.25rem;
}


nav {
    background-color: #76B3BF;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    position: fixed;
    width: 100%;
    height: 10vh;
}

#links-align-right {
    width: 40%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
}