@font-face {
    font-family: "font";
    src: url("../fonts/Hind-Medium.ttf");
}

@font-face {
    font-family: h1;
    src: url("../fonts/PragatiNarrow-Bold.ttf");
}

@font-face {
    font-family: sub-heading;
    src: url("../fonts/Basic-Regular.ttf");
}

html {
    background-color: #D1beb0;
}

a {
    text-decoration: none;
    color: #383f51;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: #383f51;
}

h1 {
    font-family: h1;
}

h2,
h3,
h4,
h5,
h6 {
    font-family: sub-heading;
}

p,
a {
    font-family: font;
}

/* grid to make home */
.home-grid {
    display: grid;
    grid-template-columns: 2fr 7fr 1fr 2fr;
    grid-template-rows: min-content 2fr max-content;
    grid-template-areas: "success success lit lit""options options options options""by empty empty site";
    width: 100%;
    height: 100vh;
    font-family: font;
    align-items: center;
}

/* different children of grid-home */
.success-title {
    grid-area: success;
    font-size: 10rem;
    padding-left: 5vw;
    margin-top: -2%;
    margin-bottom: 0;
}

.lit-project {
    grid-area: lit;
    font-size: 2.5rem;
    padding-top: 3vh;
    padding-right: 2vw;

}

.options {
    grid-area: options;
    /* grid to make options */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    justify-content: space-evenly;
    grid-gap: 3vw;
    width: 90%;
    margin-left: 5%;
}

.by-james {
    grid-area: by;
    padding-left: 3vw;
    font-size: 1.5rem;
}

.visit-site {
    grid-area: site;
    text-align: right;
    padding-right: 3vw;
    font-size: 1.5rem;
}

/* making options look good! */

.meaning-option {
    background-color: #8A9CC3;
    height: 85%;
    border-radius: 15px;
    text-align: center;
    padding-top: 10%;
    font-size: 1.25rem;
    padding-bottom: 5vh;

    /* hover off */
    animation: home-option-blue 1s;
}

.meaning-option h3 {
    font-size: 2.5rem;
}

.meaning-option:hover {
    /* hover on */
    animation: home-option-blue-hover 1s forwards;
}

.measuring-option {
    background-color: #f0a5b0;
    height: 80%;
    border-radius: 15px;
    text-align: center;
    padding-top: 10%;
    font-size: 1.25rem;
    padding-bottom: 5vh;

    /* hover off */
    animation: home-option-pink 1s;
}

.measuring-option h3 {
    font-size: 2.5rem;
}

.measuring-option:hover {
    /* hover on */
    animation: home-option-pink-hover 1s forwards;
}

.people-option {
    background-color: #8A9CC3;
    height: 80%;
    border-radius: 15px;
    text-align: center;
    padding-top: 10%;
    font-size: 1.25rem;
    padding-bottom: 5vh;

    /* hover off */
    animation: home-option-blue 1s;
}

.people-option h3 {
    font-size: 2.5rem;
}

.people-option:hover {
    /* hover on */
    animation: home-option-blue-hover 1s forwards;
}

.achieving-option {
    background-color: #f0a5b0;
    height: 80%;
    border-radius: 15px;
    text-align: center;
    padding-top: 10%;
    font-size: 1.25rem;
    padding-bottom: 5vh;

    /* hover off */
    animation: home-option-pink 1s;
}

.achieving-option h3 {
    font-size: 2.5rem;
}

.achieving-option:hover {
    /* hover on */
    animation: home-option-pink-hover 1s forwards;
}

/* animations */

@keyframes home-option-blue-hover {
    from {
        background-color: #8A9CC3;
    }

    to {
        background-color: #A5BBD5;
    }
}

@keyframes home-option-blue {
    from {
        background-color: #A5BBD5;
    }

    to {
        background-color: #8A9CC3;
    }
}

@keyframes home-option-pink-hover {
    from {
        background-color: #f0a5b0;
    }

    to {
        background-color: #f3b3be;
    }
}

@keyframes home-option-pink {
    from {
        background-color: #f3b3be;
    }

    to {
        background-color: #f0a5b0;
    }
}


/* svg animations */
.meaning-preview {
    width: 100%;
    background-image: url(../images/meaning-preview.svg);
    background-size: cover;
    height: 0;
    padding: 0;
    /* reset */
    padding-bottom: calc(100% * 2.8 / 5);
    margin-left: 5%;
}

.measurement-preview {
    width: 90%;
    background-image: url(../images/measurement-preview.svg);
    background-size: cover;
    height: 0;
    padding: 0;
    /* reset */
    padding-bottom: calc(100% * 5 / 10);
    margin-left: 5%;
}

.people-preview {
    width: 100%;
    background-image: url(../images/people-preview.svg);
    background-size: cover;
    height: 0;
    padding: 0;
    /* reset */
    padding-bottom: calc(100% * 2.8 / 5);
    margin-left: 5%;
}

.achieve-preview {
    width: 90%;
    background-image: url(../images/achieve-preview.svg);
    background-size: cover;
    height: 0;
    padding: 0;
    /* reset */
    padding-bottom: calc(100% * 5 / 10);
    margin-left: 5%;
}

.home-grid {
    min-height: 100vh;
}