@font-face {
    font-family: "font";
    src: url("../fonts/Hind-Medium.ttf");
}

@font-face {
    font-family: h1;
    src: url("../fonts/PragatiNarrow-Bold.ttf");
}

@font-face {
    font-family: sub-heading;
    src: url("../fonts/Basic-Regular.ttf");
}

h1 {
    font-family: h1;
    font-size: 7rem;
}

h2,
h3,
h4,
h5,
h6 {
    font-family: sub-heading;
    font-size: 5rem;
}

p,
a {
    font-family: font;
    font-size: 5rem;
}

.sec1,
.sec2,
.sec3,
.sec4,
.sec5,
.sec6,
.sec7 {
    height: 100vh;
    width: 90vw;
    font-size: 4rem;
    font-family: font;
    font-weight: bolder;
    display: grid;
    grid-template-columns: 5fr 5fr;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
    padding: 5vw;
}

.sec1 {
    grid-template-columns: 4fr 7fr;
}

.blue {
    background-color: #8A9CC3;
}

.pink {
    background-color: #f0a5b0;
}

.blue .sec2,
.blue .sec4,
.blue .sec6 {
    background-color: #A5BBD5;
}

.pink .sec2,
.sec4,
.sec6 {
    background-color: #f3b3be;
}

footer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 5vw;
    justify-content: space-around;
    align-items: center;
    background-color: #76B3BF;
    padding: 5vw;
    text-align: center;
}

footer a {
    font-family: Helvetica;
    font-weight: bolder;
    font-size: 2rem;
}

.svg {
    width: 100%;
    background-size: cover;
    height: 0;
    padding: 0;
    /* reset */
    padding-bottom: calc(100% * 2.8 / 5);
    margin-left: 5%;
}

.different-people {
    background-image: url(../images/undraw_celebration_0jvk1.svg);
}

.goal {
    background-image: url(../images/goals.svg);
}

.running {
    background-image: url(../images/running.svg);
}

.love {
    background-image: url(../images/love.svg);
}

.job {
    background-image: url(../images/job.svg);
}

.money {
    background-image: url(../images/money.svg);
    padding-bottom: calc(100% * 3/5);
}

.goals {
    background-image: url(../images/goals-blue.svg);
}

.ambition {
    background-image: url(../images/ambition.svg);
}

.determination {
    background-image: url(../images/determination.svg);
}

.randomness {
    background-image: url(../images/randomness.svg);
}

.holding-the-world {
    background-image: url(../images/undraw_the_world_is_mine_nb0e1.svg);
    padding-bottom: calc(100% * 4 / 5);
}

.footer-link {
    color: #383f51;
    animation: footer-link 1s;

}

.footer-link:hover {
    animation: footer-link-hover 1s forwards;
}

@keyframes footer-link-hover {
    from {
        color: #383f51;
    }

    to {
        color: rgb(53, 105, 117);
    }
}

@keyframes footer-link {
    from {

        color: rgb(53, 105, 117);
    }

    to {
        color: #383f51;
    }
}

.seven-three {
    grid-template-columns: 7fr 3fr;
}